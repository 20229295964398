import * as React from "react";
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import "app.css";
import { Password } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { useHistory } from "react-router-dom";
import { API_Host } from "Api_utils";
import { addReward } from "../../../redux/slices/RewardSlice";
import Swal from "sweetalert2";
import { getTask } from "../../../redux/slices/TaskSlice";
import { useEffect } from "react";

function AssignTask() {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [taskData, setTaskData] = useState({
    taskName: "",
    // taskType: "",
    taskFile: "",
    assigned: [],
    status: "",
    rewardPoints: "",
    submission: "",
  });
  const [tasks, setTasks] = useState([]);
  const [students, setStudents] = useState([]);

  const [taskError, setTaskError] = useState({});
  const course = localStorage.getItem("course");

  // Fetch tasks from API
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const res = await axios.get(`${API_Host}/get-task`);
        dispatch(getTask(res.data.data)); // Save tasks in Redux state
        setTasks(res.data.data); // Store tasks in local state
      } catch (err) {
        console.error(err);
      }
    };
    fetchTasks();

    const fetchUser = async () => {
      try {
        const res = await axios.get(`${API_Host}/get-student-course/${course}`);
        console.log(res.data);
        setStudents(res.data);
        // dispatch(getTask(res.data.data));  // Save tasks in Redux state
        // setTasks(res.data.data);  // Store tasks in local state
        // console.log(res.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchUser();
  }, []);
  console.log(students);

  // Handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;

    // If the user selects a taskName, auto-fill the other fields
    if (name === "taskName") {
      const selectedTask = tasks.find((task) => task.taskName === value);
      if (selectedTask) {
        setTaskData((prevData) => ({
          ...prevData,
          taskName: selectedTask.taskName,
          // taskType: selectedTask.taskType,
          taskFile: selectedTask.fileName,
          course: localStorage.getItem("course"),
          status: selectedTask.status,
          rewardPoints: selectedTask.rewardPoints,
          // Add more fields if needed
        }));
      }
      console.log(selectedTask.status);
    } else {
      // For other fields, handle normally
      setTaskData((prevData) => ({
        ...prevData,
        [name]: name === "assigned" ? [...value] : value, // Ensure 'assign' stores an array of selected values
      }));
    }
  };
  console.log(taskData);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateTask()) {
      return; // Exit if validation fails
    }

    try {
      const course = localStorage.getItem("course");
      const token = localStorage.getItem("token"); // Assuming the token is stored in localStorage

      // Loop over the 'assign' array (which contains the selected users)
      await Promise.all(
        taskData.assigned.map(async (userEmail) => {
          console.log(userEmail);

          //
          // const currentDate = new Date();
          // const submissionDate = new Date(taskData.submission);
          // const dueDateCrossed = submissionDate < currentDate;

          const requestData = {
            taskName: taskData.taskName,
            // taskType: taskData.taskType,
            taskFile: taskData.taskFile,
            course: course,
            assigned: userEmail, // Individual user email
            status: taskData.status,
            rewardPoints: taskData.rewardPoints,
            submission: taskData.submission,
            // dueDateCrossed,
          };
          console.log(requestData);
          const token = localStorage.getItem("token");
          // Make axios call for each user with correct headers
          const response = await axios.post(`${API_Host}/assignSubmit-task`, requestData, {
            headers: {

              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
              // "Content-Type": "multipart/form-data",
            },
          });

          // Handle the response (optional)
          console.log(`Task assigned to ${userEmail}:`, response.data);
        })
      );

      // Show success message after all tasks have been assigned
      Swal.fire({
        title: "Task Assigned Successfully!",
        text: "The task has been assigned to all selected users.",
        icon: "success",
      });
      history.push("/view-assigned-task");
    } catch (error) {
      console.error("Error assigning tasks:", error);
      Swal.fire({
        title: "Error",
        text: "There was an error while assigning the tasks. Please try again.",
        icon: "error",
      });
    }
  };

  const validateTask = () => {
    const error = {};
    if (!taskData.taskName) error.taskName = "Task name is required";
    // if (!taskData.taskType) error.taskType = "Task type is required";
    if (!taskData.rewardPoints) error.rewardPoints = "Reward points is required";
    if (!taskData.taskFile) error.taskFile = "Task file is required";
    if (!taskData.assigned) error.taskFile = "Assigned is required";

    setTaskError(error);
    return Object.keys(error).length === 0;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ width: "100%",height:'100vh' }} mt={10}>
        <VuiBox component="form" role="form" onSubmit={handleSubmit}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {/* Task Name */}
            <Grid item xs={12} sm={6}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Task Name
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <Select
                    sx={{
                      backgroundColor: "#0f1535 !important",
                      color: "white !important",
                      borderRadius: "0.975rem !important",
                    }}
                    name="taskName"
                    value={taskData.taskName}
                    onChange={handleChange}
                    error={!!taskError.taskName}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Task Name
                    </MenuItem>
                    {tasks.map((task, index) => (
                      <MenuItem key={index} value={task.taskName}>
                        {task.taskName}
                      </MenuItem>
                    ))}
                  </Select>
                </GradientBorder>
                {taskError.taskName && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskError.taskName}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>

            {/* Task Type */}
            <Grid item xs={12} sm={6}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Reward Points
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  {/* <Select
                    sx={{
                      backgroundColor: "#0f1535 !important",
                      color: "white !important",
                      borderRadius: "0.975rem !important"
                    }}
                    name="taskType"
                    value={taskData.taskType}
                    onChange={handleChange}
                    error={!!taskError.taskType}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Task Type
                    </MenuItem>
                    {tasks.map((task, index) => (
                      <MenuItem key={index} value={task.taskType}>
                        {task.taskType}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <VuiInput
                    type="number"
                    placeholder="Enter Reward Points..."
                    name="rewardPoints"
                    value={taskData.rewardPoints}
                    onChange={handleChange}
                    error={!!taskError.rewardPoints}
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                    })}
                  />
                </GradientBorder>
                {taskError.rewardPoints && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskError.rewardPoints}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>

            {/* Task File */}
            <Grid item xs={12} sm={6}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Task File
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <Select
                    sx={{
                      backgroundColor: "#0f1535 !important",
                      color: "white !important",
                      borderRadius: "0.975rem !important",
                    }}
                    name="taskFile"
                    value={taskData.taskFile}
                    onChange={handleChange}
                    error={!!taskError.taskFile}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Task File
                    </MenuItem>
                    {tasks.map((task, index) => (
                      <MenuItem key={index} value={task.fileName}>
                        {task.fileName}
                      </MenuItem>
                    ))}
                  </Select>
                </GradientBorder>
                {taskError.taskFile && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskError.taskFile}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>

            <Grid item xs={12} sm={6}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Submission date
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <VuiInput
                    type="date"
                    placeholder="Enter Submitted Date..."
                    name="submission"
                    value={taskData.submission}
                    onChange={handleChange}
                    error={!!taskError.submission}
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                    })}
                  />
                </GradientBorder>
                {taskError.submission && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskError.submission}
                  </VuiTypography>
                )}

               
              </VuiBox>
            </Grid>

            <Grid item xs={12} sm={6}>
              <VuiBox mb={1} ml={2}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Assign
                </VuiTypography>
                <GradientBorder
                  minWidth="100%"
                  padding="1px"
                  borderRadius={borders.borderRadius.lg}
                  backgroundImage={radialGradient(
                    palette.gradients.borderLight.main,
                    palette.gradients.borderLight.state,
                    palette.gradients.borderLight.angle
                  )}
                >
                  <Select
                    sx={{
                      backgroundColor: "#0f1535 !important",
                      color: "white !important",
                      borderRadius: "0.975rem !important",
                      display: "flex",
                      flexWrap: "wrap", // Ensure multiple selected items wrap
                      gap: 1, // Optional: Gap between selected items
                      width: "100%", // Ensures it takes the available space
                    }}
                    multiple // Enable multiple selection
                    name="assigned"
                    value={taskData.assigned} // should be an array now
                    onChange={handleChange}
                    error={!!taskError.assigned}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select Students</em>;
                      } else if (selected.length > 4) {
                        return `${selected.length} members selected`;
                      } else {
                        return selected.join(", "); // Show selected users as comma-separated
                      }
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Students
                    </MenuItem>
                    {students.map((student, index) => (
                      <MenuItem key={index} value={student.email}>
                        {student.email}
                      </MenuItem>
                    ))}
                  </Select>
                </GradientBorder>
                {taskError.assigned && (
                  <VuiTypography variant="caption" color="error" mt={1}>
                    {taskError.assigned}
                  </VuiTypography>
                )}
              </VuiBox>
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} md={4}>
              <VuiBox mt={5} mb={1}>
                <VuiButton color="info" fullWidth type="submit">
                  Submit
                </VuiButton>
              </VuiBox>
            </Grid>
          </Grid>
        </VuiBox>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default AssignTask;
