import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Vision UI Dashboard React routes
import { getAdminRoutes,getStudentRoutes } from "./routes";

// Vision UI Dashboard React contexts
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import UpdateStudent from "layouts/EnrollStudent/UpdateStudent";
import UpdateInstructor from "layouts/Instructor/UpdateInstructor";
import AddRewards from "layouts/Rewards/AddRewards";
import UpdateRewards from "layouts/Rewards/UpdateRewards";
import AddTask from "layouts/Task/AddTask";
import UpdateTask from "layouts/Task/UpdateTask";
import AddQuiz from "layouts/quiz/AddQuiz";
import UpdateQuiz from "layouts/quiz/UpdateQuiz";
import AddCourses from "layouts/addcourses/AddCourses";
import UpdateCourses from "layouts/addcourses/UpdateCourses";
import EnrollStudent from "layouts/EnrollStudent/EnrollStudent";
import Instructor from "layouts/Instructor/Instructor";
import { useSelector } from "react-redux";
import SubmitQuiz from "layouts/quiz/SubmitQuiz";
import AssignTask from "layouts/Task/Instructor/AssignTask";
import EditAssignTask from "layouts/Task/Instructor/EditAssignTask";
import ViewAssignedTask from "layouts/Task/viewAssignedTask";
import ViewQuiz from "layouts/quiz/ViewQuiz";
import ViewTask from "layouts/Task/ViewTask";
import QuizResult from "layouts/quiz/ResultQuiz";
import ShareComponent from "components/ShareComponent";
import Profile from "layouts/profile";
import { PaginationProvider } from "PaginationContext/TablePagination";
import Table from "examples/Tables/Table";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const columns=[{}];
  const rows=[{}]
  // const {role,setRole} = useState();

  // console.log(userRole);
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // useEffect(()=>{
  //     setRole(userRole);

  //   // const role = () => {
  //   //   setRole(userRole);
  //   // }
  //   // role();
  // },[])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => 
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);  // Recursively render routes if there's collapse
      }

      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  // const configsButton = (
  //   <VuiBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.5rem"
  //     height="3.5rem"
  //     bgColor="info"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="white"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="default" color="inherit">
  //       settings
  //     </Icon>
  //   </VuiBox>
  // );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand=""
              brandName="CAREER LINES"
              routes={getAdminRoutes()}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Switch>
        {getRoutes(getAdminRoutes())}

          <Redirect from="*" to="/dashboard" />
        </Switch>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand=""
            brandName="CAREER LINES"
            routes={getAdminRoutes()}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Switch>
        {getRoutes(getAdminRoutes())}
        {/* Additional routes */}
        <Route exact path="/add-student" component={EnrollStudent} />
        <Route exact path="/update-student/:id" component={UpdateStudent} />
        <Route exact path="/add-instructor" component={Instructor} />
        <Route exact path="/update-instructor/:id" component={UpdateInstructor} />
        <Route exact path="/add-rewards" component={AddRewards} />
        <Route exact path="/update-rewards/:id" component={UpdateRewards} />
        <Route exact path="/add-task" component={AddTask} />
        <Route exact path="/update-task/:id" component={UpdateTask} />
        <Route exact path="/add-quiz" component={AddQuiz} />
        <Route exact path="/update-quiz/:id" component={UpdateQuiz} />
        <Route exact path="/quiz" component={ViewQuiz} />
        <Route exact path="/add-courses" component={AddCourses} />
        <Route exact path="/update-course/:id" component={UpdateCourses} />
        <Route exact path="/particular-quiz/:id" component={SubmitQuiz} />
        <Route exact path="/assign-task" component={AssignTask} />
        <Route exact path="/task" component={ViewTask} />
        <Route exact path="/view-assigned-task" component={ViewAssignedTask} />
        <Route exact path="/edit-assigned-task/:id" component={EditAssignTask} />
        <Route exact path="/quiz-result/:id" component={QuizResult} />
        {/* <Route exact path="/share" component={ShareComponent} /> */}
        <Route exact path="/profile" component={Profile} />

{/* 
<PaginationProvider>
<Table columns={columns} rows={rows} />
</PaginationProvider> */}
        <Redirect to="/authentication/sign-in" />
      </Switch>
    </ThemeProvider>
  );
}
